import { createSelector } from '@reduxjs/toolkit';
import { UniqueIdentifier } from '../../../types';

export interface TenantConfig {
  id: UniqueIdentifier;
  outputName: string;
  brandHubId: string;
  copyright: string;
  support: {
    privacyEmail: string;
    techSupportEmail: string;
    customerServiceEmail: string;
  };
}

export const selectTenantConfig = createSelector(
  [(state) => state.pageConfig.tenantConfig as TenantConfig],
  (data) => data,
);
