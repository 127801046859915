import { useMemo } from 'react';
import loadable from '@loadable/component';
import { HubFooter, HubSector } from '../../../../types';
import { useRootSelector } from '../../../../store/hooks';
import { selectTenantConfig } from '../../../../store/features/tenantConfig';
import IMDALogo from '../../../../assets/images/festivals/IMDA-logo-white.png';
import { ImageTag } from '../../../shared/ImageTag/ImageTag';

const ClassicFooter = loadable(
  () =>
    import(
      /* webpackChunkName: "ClassicFooter" */ '../../../footers/ClassicFooter'
    ),
);

export interface FooterProps {
  footer: HubFooter;
  sectors: HubSector[];
}

export function Footer({
  footer: {
    footerLogo,
    footerLinks,
    footerLinksTitle,
    publicLinks,
    socialLinks,
  },
  sectors,
}: FooterProps) {
  const { copyright, brandHubId } = useRootSelector(selectTenantConfig);

  const sectorLinks = useMemo(
    () =>
      sectors.map((sector) => ({
        url: `/${sector.brandUrlSuffix}/${sector.path}/`,
        text: sector.name,
        openInNewTab: false,
      })),
    [sectors],
  );

  const getCustomContent = () => {
    if (brandHubId === 'ASIATECHXSG') {
      return <ImageTag alt="Asia Tech X" src={IMDALogo} imgix={false} />;
    }

    return null;
  };

  return (
    <ClassicFooter
      customContent={getCustomContent()}
      primaryNav={sectorLinks}
      primaryNavHeading="Sectors"
      secondaryNav={footerLinks}
      secondaryNavHeading={footerLinksTitle}
      socialNetworkNav={socialLinks}
      publicNav={publicLinks}
      logo={footerLogo}
      logoAlt="Brand Logo"
      copyright={copyright}
    />
  );
}
